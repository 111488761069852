@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700&subset=latin,cyrillic-ext);

$font-size: 16px;
$line-height: 1.4;

$font-light: 300;
$font-regular: 400;
$font-bold: 700;


body {
  font-family: 'Open Sans', sans-serif;
  font-weight: $font-regular;
  font-size: $font-size;
  line-height: $line-height;
}
h1, h2, h3, h4 {
	line-height: $line-height;
	margin: 0;
}
p {
	margin: 0 0 10px;
}
