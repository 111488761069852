@import "variables";

@import "layouts/_all.scss";
@import "layouts/news.scss";

@define-mixin clf {
  *zoom: 1;
  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    display: block;
    clear: both;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
    width: 0;;
  }
}

@define-mixin breakpoint $point  {
  @if $point == retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi) { @mixin-content; }
  }
  @else {
    @media only screen
    and (max-width : $point)  { @mixin-content; }
  }
}

@define-mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


@define-mixin font-smoothing $value: on {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@import "fonts";
@import "fancy";
@import "hamburger";
@import "tooltip";
@import "slick";

@import "../../../../node_modules/font-awesome/css/font-awesome.css";

.mobile-only {
  display: none !important;
  @mixin breakpoint 700px {
    display: block !important;
  }
}
.no-mobile {
  @mixin breakpoint 700px {
    display: none !important;
  }
}

body, html {
  margin: 0;;
  padding: 0;
  height: 100%;
}

body {
  @mixin breakpoint retina {
    @mixin font-smoothing on;
  }
}

.fl-l { display: block; float: left; }
.fl-r { display: block; float: right; }
.clf { @mixin clf; }
img {
  max-width: 100%;
}
.hover {
  user-select: none;
  touch-callout: none
}

.pagination {
  width: 100%;
  clear: both;
  margin-top: 10px;
  margin-bottom: 20px;
  list-style: none;
  font-size:20px;
  text-align: center;
  li {
    display: inline-block;
    margin: 0 5px;
    a {
      &:hover {
        color: $orange;
      }
    }
  }
}

.notification {
  position: fixed;
  top: 0;;
  left: 0;
  padding: 8px 10px 8px 50px;
  width: 100%;
  font-size: 15px;
  z-index: 5;
  min-height: 40px;
  background-color: rgba(0, 162, 81, 0.88);
  color: transparent;;
  box-sizing: border-box;
  ul {
    list-style: none;
  }
  &.success {
    background-color: rgba(0, 162, 81, 0.88);
    color: white;
  }
  &.error {
    background-color: rgba(181, 37, 37, 0.81);
    color: white;
  }
}

.color-section {
  position: relative;
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;;
    z-index: -1;
    width: 200%;
    height: 100px;
    left: -20%;
    transform: rotate(3deg);
  }
  &:before {
    top: -28px;
  }
  &:after {
    bottom: -70px;
  }
}
.dark-section {
  background-color: $dark;
  &:before, &:after {
    background-color: $dark;;
  }
}
.orange-section {
  background-color: $orange;
  &:before, &:after {
    background-color: $orange;
  }
}
.aqua-section {
  background-color: $aqua;
  &:before, &:after {
    background-color: $aqua;
  }
}

.big-wrapper {
  overflow-x: hidden;
  height: 100%;
}


.wrapper {
  @mixin clf;
  max-width: $wrapper-w;
  margin: 0 auto;
  position: relative;
  @media only screen and (min-width : 1366px) {
    max-width: 1350px;
  }
  @mixin breakpoint $wrapper-w {
    padding: 0 30px;
    box-sizing: border-box;
  }
}

/*Header starts*/
.header {
  padding: 30px 0;
  background-color: $orange;
  font-size: 14px;
  cursor: default;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 200%;
    height: 100px;
    left: -20%;
    bottom: -70px;
    transform: rotate(3deg);
    background-color: $orange;
  }

  .homepage & {
    background-color: $dark;
    &:after {
      content: none;
    }
  }
  .hamburger-icon {
    display: none;
    z-index: 4;
    position: absolute;
    top: 10px;
    right: 0px;
    @mixin breakpoint 700px {
      display: block;
    }
  }
  .nav {
    @mixin breakpoint 700px {
      display: none;
      position: absolute;
      text-align: center;
      top: -23px;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, .9);
      text-align: center;
      margin: 0;
      z-index: 1;
      padding: 30px 0 20px;
      font-size: 24px;
      ul {
        margin: 0;
        li {
          float: none;
          display: block;
          margin: 0;
          border: none;
          a {
            display: inline-block;
            padding: 20px 0;
            opacity: .7;
            &:hover {
              opacity: .85;
              text-decoration: underline;
            }

          }
          &.active {
            a {
              opacity: 1;
            }

            &:hover {
              opacity: 1;
              a {
                opacity: 1;
                text-decoration: none;
              }
            }
          }
        }
      }

      &.opened {
        z-index: 3;
        display: block;
      }
    }
  }
}

.logo-wrap {
  position: relative;
  top:5px;
  z-index: 1;
  width: 20%;
  min-width: 128px;
  h2 {
    margin-top: 10px;
    color: white;
    font-size:14px;
    font-weight:$font-regular;
    width: 100%;
    opacity:.7;
  }
  @mixin breakpoint 900px {
    width: 18%;
    margin-right: 2%;
  }
  .logo {
    top:0;
    width: 100%;
  }
  .homepage & {
    .logo:hover { background-image: inline('../img/logo-original-aqua.png'); }
  }
}

.logo {
  display: block;
  position: relative;
  z-index: 1;
  top: 5px;
  background-image: inline('../img/logo.png');
  /*.homepage & {
    background-image: inline('../img/logo-original.png');
  }
  .footer & {
    background-image: inline('../img/logo.png');
  }*/
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 128px;;
  width: 20%;
  height: 38px;
  @mixin breakpoint 900px {
    width: 18%;
    min-width: 100px;
    margin-right: 2%;
  }
}

.nav {
  width: 64%;
  font-size: 14px;
  position: relative;
  z-index: 2;
  ul {
    padding: 0;
    list-style: none;
  }
  li {
    display: inline-block;
    margin-right:5%;
    &:last-of-type {
      margin-right: 0;
    }
    a {
      color: white;
      opacity: .7;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-decoration: none;
      font-weight:$font-regular;
      &:hover {
        opacity: .9;
      }
    }
    &.active a {
      opacity: 1;
    }
  }
}

.lang {
  position: relative;
  z-index: 1;
  top: 14px;
  a, span {
    display: inline-block;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    opacity: .7;
    margin-right: 10px;
    &:last-child {
      margin-right:0;
    }
    &:hover {
      opacity: .9;
    }
    &.active {
      opacity: 1;
    }
  }
}
/*Header ends*/


/*Homepage starts*/
[class^='home-'] {
  color: white;
  h1, h2 {
    font-weight:$font-light;
  }
  h1 {
    font-size:38px;
    span {
      display: block;
      font-size:80px;
      i {
        font-style: normal;
        color: $aqua;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h2 {
    font-size:32px;
  }
  .description {
    font-size:18px;
    font-weight:$font-light;
  }

  &.rounded {
    h1 {
      font-size:34px;
      font-weight:$font-bold;
    }
  }
}
.section-about {
  padding: 50px 0 70px;
  @mixin breakpoint 960px {
    padding-top: 0;
  }
  .wrapper {
    position: static;
  }
}
.home-about {
  max-width: 400px;
  margin-left: 20%;
  clear: both;
  position: relative;
  z-index: 1;
  @mixin breakpoint 960px {
    margin-bottom: 50px;
    max-width: 500px;
  }
  @mixin breakpoint 600px {
    margin-left: 0;
  }
  h1 {
    margin-bottom: 15px;
  }
}
.home-one {
  margin-top: 60px;
  max-width: 620px;
  max-width: 565px;
  clear: both;
  position: relative;
  z-index: 1;
  h1 {
    font-size:28px;
    text-align: right;
  }
  h1, .home-gallery {
    width: 50%;
  }
  .home-gallery {
    box-sizing: border-box;
    padding-left: 30px;;
    float: right;
    .slider {
      overflow: hidden;
      z-index: 1;
      width:250px !important;
      margin: 0 auto;
      border-radius: 50%;
      border: 2px solid white;
      .slide {
        outline: none;
        background-size: cover;
        width:250px; height: 250px;
        background-position: center center;
        background-repeat:no-repeat;
        position: relative;
        &:hover {
          .overlay {
            opacity: 1;
          }
        }
        .overlay {
          opacity: 0;
          transition: opacity .2s ease;
          position: absolute;
          width:100%;
          height:100%;
          background-color:rgba(0,0,0,.7);
          display: table;
          .title {
            color: white;
            font-size:24px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
          }
        }
      }
    }
  }
  @mixin breakpoint 1100px {
    margin-top: 80px;
  }
  @mixin breakpoint 960px {
    max-width: 70%;
    clear: none;
    h1 {
      font-size:20px;
      span {
        font-size:63px;
      }
    }
  }
  @mixin breakpoint 700px {
    margin-top: 35px;
  }
  @mixin breakpoint 600px {
    max-width: none;
    width: 100%;
    float: none;
  }
  @mixin breakpoint 450px {
    h1 {
      width: 70%;
      text-align: left;
    }
    .home-gallery {
      width: 100%;
      padding-left: 0;
      margin-top: 10px;
    }
  }
}

.poster {
  position: absolute;
  z-index: 1;
  .img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
    transition: filter .2s ease;
  }
  &:hover, &.touch_hover {
    .img {
      filter: none;
    }
  }
  .desc {
    position: absolute;
    top: 30px;
    right: 20px;
    display: block;
    color: white;
    font-size:24px;
    font-weight:$font-bold;
    width: 50%;
    i {
      font-style: normal;
      color: $aqua;
    }
  }
  span.big {
    display: block;
    font-size:35px;
    text-transform: uppercase;
    font-weight:$font-light;
  }
  &.dream {
    .img {
      background-image: inline('../img/posters/dream-color.png');
      background-position: center top;
    }
    width: 25%;
    height: 295px;
    right: 240px;
    top: -155px;
    .desc {
      font-size: 19px;
      top: 70px;
      right: 20px;
      width: 120px;
    }

    @media only screen and (min-width : 1366px) {
      right: 300px;
      .desc {
        right: 30px;
        top:90px;
      }
    }

    @media only screen and (min-width : 1566px) {
      .desc {
        right: 60px;
        top: 50px;
      }
    }
  }
  &.donttalk {
    .img {
      background-image: inline('../img/posters/donttalk-color.png');
      background-position: center top;
    }
    width: 20%;
    height: 235px;
    right: 30px;
    top: 50px;
    .desc {
      right: 0px;
      top: 35px;
      width: 117px;
    }

    @media only screen and (min-width : 1366px) {
      top: 0px;
      .desc {
        top:40px;
        right:10px;
      }
    }
    @media only screen and (min-width : 1566px) {
      .desc {
        right: 30px;
      }
    }
  }
  &.you {
    .img {
      background-image: inline('../img/posters/you-color.png');
      background-position: center bottom;
    }
    width: 27%;
    height: 330px;
    top: 230px;
    left: 10px;
    z-index: 2;
    .desc {
      right: -15px;;
      max-width: 200px;
      top: 50px;
    }
    &:before, &:after {
      top: 20%;
    }
    @media only screen and (min-width : 1566px) {
      .desc {
        right: 15px;
      }
    }
  }
  &.cando {
    .img {
      background-image: inline('../img/posters/cando-color.png');
      background-position: center bottom;
    }
    width: 20%;
    height:315px;
    bottom: -43px;
    left: 380px;
    transform: rotate(3deg);
    .desc {
      top:11%;;
      right: 0;
      transform: rotate(-3deg);
    }
    &:before, &:after {
      transform: rotate(-3deg);
    }
    &:before {
      margin-top: -16px;
    }

    span {
      display: block;
      &:nth-of-type(2) {
        margin-left: 20px;
      }
      &:nth-of-type(3) {
        margin-left: 40px;
      }
    }


    @media only screen and (min-width : 1366px) {
      left: 470px;
      bottom: -30px;
    }
  }


  @mixin breakpoint 1100px {
    .desc {
      font-size:22px;
    }
    &.dream {
      right: 16%;
      .desc {
        font-size: 18px;
        top: 57px;
        right: 20px;
        width: 100px;
      }
    }
    &.donttalk {
      top: 85px;
      .desc {
        right: 3px;
        top: 35px;
        width: 90px;
        font-size: 18px;
      }
    }
    &.cando {
      width: 20%;
      height: 315px;
      bottom: -45px;
      left: 337px;
      .desc {
        top: 24%;
        font-size:20px;
      }
    }
    &.you {
      width: 23%;
      top: 160px;
      .desc {
        font-size:18px;
        right: -13px;
        top: 105px;
        .big {
          font-size:30px;
        }
      }
    }
  }

  @mixin breakpoint 1050px {
    &.cando {
      display: none;
    }
    &.you {
      width: 28%;
    }
  }

  @mixin breakpoint 960px {
    position: relative;
    display: block;
    float: left;
    left: 0 !important;right:0 !important;
    top:0 !important;bottom:0 !important;
    width: 30% !important;
    height: 200px !important;
    margin-right: 5%;
    .img {
      background-position: top center !important;
    }
    &:first-of-type {
      clear: left;
    }
    &:nth-of-type(3){
      clear: right;
      margin-right: 0;
    }

    &.dream {
      .desc {
        font-size: 16px;
        top: 26px;
        right: 7px;
      }
    }
    &.you {
      .desc {
        font-size: 15px;
        right: -9px;
        top: 9px;
      }
    }

    &.cando {
      display: block;
      margin-top:80px;
      width: 30% !important;
      margin-right: 0;
      height: 275px !important;
      transform: none;
      .desc {
        top: 9%;
        transform: none;
      }
    }
  }

  @mixin breakpoint 700px {
    &.dream {
      .desc {
        font-size: 15px;
        top: 15px;
        right: -10px;
      }
    }
    &.you {
      .desc {
        font-size: 14px;
        max-width: 110px;
        right: 20px;
        top: 20px;
      }
    }
    &.cando {
      margin-top: 35px;
      .desc {
        font-size: 17px;
        top: 5%;
      }
    }
    &.donttalk {
      .desc {
        right: -8px;
        top: 23px;
        font-size: 17px;
      }
    }
  }

  @mixin breakpoint 600px {
    width:275px !important;
    max-width: 100%;
    margin: 40px auto 40px !important;
    float: none !important;
    &.cando {
      width:275px !important;
      max-width: 100%;
      margin: 40px auto 40px !important;
      .desc {
        font-size: 20px;
        top: 9%;
      }
    }
    &.dream {
      .desc {
        top: 26px;
        right: 20px;
      }
    }
    &.donttalk {
      .desc {
        right: 27px;
        top: 35px;
        font-size: 20px;
      }
    }
  }
}
/*Homepage ends*/

.section-cases, .section-press, .section-contacts, .section-page {
  .wrapper > h1 {
    color: white;
    font-weight:$font-light;
    font-size:32px;
    margin-left: 50px;
    position: relative;
    z-index: 1;
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
aside {
  position: relative;
  width: 300px;
  box-sizing: border-box;
  padding: 65px 35px 20px;
  top: -10px;
  margin-bottom: 40px;
  .section-page & {
    padding-top: 50px;
    top: -40px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -30px;
    left:0;
    width:100%;
    height:60px;
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h1 {
    color: white;
    font-weight:$font-light;
    font-size:32px;
    position: relative;
    z-index: 1;
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/*Cases starts*/
.cases-nav, .services-nav {
  width: 27%;
  padding-right: 28px;
  @mixin breakpoint 960px {
    width: 40%;
  }

  @mixin breakpoint 700px {
    width: 70%;
    top: -80px;
    padding-top: 70px;
  }

  @mixin breakpoint 450px {
    width: 100%;
    padding: 70px 25px 20px;
  }


  background-color: $orange;
  &:after {
    background-image: inline('../img/orange-bottom.png');
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      display: block;
      font-size:15px;;
      margin-bottom: 15px;
      color: white;
      font-weight:$font-regular;
      &.active {
        a {
          font-weight: $font-bold;
          text-decoration: underline;
        }
      }
      a {
        color: white;
        font-weight:$font-regular;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        cursor: pointer;
        &:hover {
          text-decoration: underline;;
        }
        i {
          margin-left: 3px;
          transition: transform .2s ease;
        }
        &.expanded {
          i {
            transform: rotate(180deg);
          }
        }
      }
      ul {
        display: none;
        padding-left: 30px;
        margin-top: 10px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.cases-nav {
  top: -30px;
  ul {
    li {
      a {
        opacity: .7;;
      }
      &.active a {
        opacity: 1;
      }
    }
  }
}

.services-nav {
  /*width: 37%;*/
  ul {
    li {
      font-size:18px;
    }
  }
}

.section-cases {
  padding-bottom: 65px;
  .wrapper > h1 {
    top: 40px;
    @mixin breakpoint 700px {
      top: -20px;;
    }
  }

  section {
    text-align: center;
    position: relative;
    top: 80px;
    width: 65%;
    @mixin breakpoint 960px {
      width: 55%;
    }
    @mixin breakpoint 700px {
      width: 100%;
      top: -35px;
    }
    h1 {
      color: $blue;
      font-size:24px;
      font-weight:$font-light;
      margin-bottom: 5px;
      a {
        color: $blue;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    h2 {
      color: $light-blue;
      font-size:14px;
      font-weight:$font-regular;
      margin: 0 auto 20px;
      max-width: 65%;
    }
    .cases-wrap {
      @mixin clf;
    }
    .cases-slider .case {
      &:nth-of-type(3n-1) {
        margin: 0;;
      }
      &:nth-of-type(3n+1) {
        clear: none;
      }
      @mixin breakpoint 450px {
        float: left;
        margin: 0 !important;
      }
    }
    .case {
      background-color: white;
      width: 30%;
      float: left;
      font-weight:$font-light;
      padding: 20px 15px;
      box-sizing: border-box;
      position: relative;
      z-index: 1;
      @mixin clf;
      &:nth-of-type(3n-1) {
        margin: 0 5%;
      }
      &:nth-of-type(3n+1) {
        clear: left;
      }


      h1 {
        font-size:17px;
      }

      a {
        outline:none;
      }



      .img-wrap {
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 35%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 10px;
        /*filter: grayscale(100%);*/
        &:hover {
          + .info h1 a {
            text-decoration: underline;;
          }
        }
      }

      /*&:hover, &.touch_hover {
        .img-wrap {
          filter: none;
        }
      }*/

      @mixin breakpoint 960px {
        /*float: none;
        width:100%;
        margin: 10px 0 0 !important;
        text-align: left;
        .img-wrap {
          width: 30%;
          float: left;
        }
        .info {
          width: 70%;
          float: right;
          box-sizing: border-box;
          padding-left: 20px;
        }
        padding: 0;
        */

        font-size: 13px;
        h1 {
          font-size:16px;
        }
      }
      @mixin breakpoint 450px {
        width: 100%;
        float: none;
        margin: 0 0 40px !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
/*Cases ends*/

/*Press starts*/
.section-press {
  padding-bottom: 25px;
  .wrapper > h1 {
    margin-top: -30px;
    @mixin breakpoint 600px {
      margin-top: 0;
      margin-left: 0;
      width: 100%;
      text-align: center;
    }
  }
  .quotes-wrap {
    @mixin clf;
  }
  .quote {
    width: 28%;
    float: left;
    text-align: center;
    font-weight:$font-light;
    padding: 20px 15px;
    color: white;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    font-size:15px;
    &:before {
      content: none;
      display: block;
      margin: 10px auto 20px;
      background-image: inline('../img/quote.png');
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

    }
    &:nth-of-type(2) {
      margin: 0 8%;
    }
    .img-wrap {
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 35%;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 10px;
      filter: grayscale(100%);
      transition: filter .2s ease;
      &:hover {
        + .desc a {
          text-decoration: underline;;
        }
      }
    }

    &:hover {
      .img-wrap {
        filter: none;
      }
    }

    .desc {
      font-style: italic;
      a {
        color: white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    h2 {
      font-weight:$font-bold;
      margin-top: 10px;
      font-size:15px;
    }
    @mixin breakpoint 960px {
      padding: 20px 0;
    }
    @mixin breakpoint 550px {
      width: 100%;
      padding: 0;
      float: none;
      margin: 0 0 40px !important;
      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }
}
/*Press ends*/


/*Contacts*/
.section-contacts {
  margin-top: 70px;
  padding-bottom: 90px;
  h1, .title {
    color: $blue;
    font-weight:$font-light;
    font-size:32px;
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
    a {
      color: $blue;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h2 {
    color: $light-blue;
    font-size:14px;
    font-weight:$font-light;
    margin-bottom: 20px;
    width: 70%;
  }

  .contacts-info {
    width: 27%;
    background-color: $aqua;
    padding-top: 20px;
    color: white;
    .title {
      margin-left:0;
      color: white;
      margin-bottom: 40px;
      a {
        color: white;
      }
    }
    p {
      margin-bottom: 20px;
    }
    a {
      color: white;
      text-decoration: none;
      display: block;
      font-size:16px;
      margin-bottom: 20px;
      &:hover {
        text-decoration: underline;
      }
    }
    &:after {
      background-image: inline('../img/aqua-bottom.png');
    }

    .fa {
      color: white;
      opacity:.7;
      display: inline-block;
      margin-right: 20px;
      font-size:19px;
      top: 1px;
      &.fa-phone {
        position: relative;
        left: 1px;
      }
    }

    .socials {
      margin-top: 30px;
      cursor: default;
      a {
        display: inline-block;
        margin-right: 20px;
        font-size:22px;
        &:last-of-type {
          margin-right: 0;
        }
        &:hover {
          opacity: 1;
          text-decoration: none;
        }
      }
    }

    @mixin breakpoint 800px {
      width: 45%;
      top: -80px;
      padding: 35px 30px 20px;
    }
    @mixin breakpoint 600px {
      top: 0;
      width: 100%;
    }


  }
}

.feedback {
  width: 65%;
  box-sizing: border-box;
  padding-left: 50px;

  label {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    &.half {
      width: 48%;
      &:first-of-type {
        margin-right: 4%;
      }
    }
    span {
      display: block;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight:$font-bold;
      color: #adb3b9;
    }
    input, textarea {
      display: block;
      width: 100%;
      border: none;
      box-shadow: none;
      border-radius: 0;
      border-bottom:1px solid rgba(0,0,0,.1);
      font-size:20px;
      color: black;
      height: 50px;
      font-weight:$font-light;
      outline: none;
      &::-webkit-input-placeholder {color:rgba(0,0,0,.1)}
      &::-moz-placeholder          {color:rgba(0,0,0,.1)}/* Firefox 19+ */
      &:-moz-placeholder           {color:rgba(0,0,0,.1)}/* Firefox 18- */
      &:-ms-input-placeholder      {color:rgba(0,0,0,.1)}

      &:focus::-webkit-input-placeholder {color: transparent}
      &:focus::-moz-placeholder          {color: transparent}
      &:focus:-moz-placeholder           {color: transparent}
      &:focus:-ms-input-placeholder      {color: transparent}
    }
    textarea {
      margin-top: 10px;
      width:100%;
      height: 70px;
      resize: none;
    }
  }
  .send {
    background-image: inline('../img/send.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    box-shadow: none;
    float: right;
    clear: both;
    position: relative;
    top: -45px;
    right: -20px;
    cursor: pointer;
    outline: none;
    &:hover {
      opacity: .7;
    }
  }


  @mixin breakpoint 800px {
    width: 50%;
    padding: 0;
    padding-right: 35px;
    h2 {
      width: 90%;
    }
  }
  @mixin breakpoint 600px {
    width: 100%;
    label {
      &.half {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
/*Contacts ends*/

/*Page starts*/
.breadcrumbs {
  list-style: none;
  color: #fff;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 0;
  z-index:2;
  box-sizing: border-box;
  li {
    display: inline-block;
    a, span {
      display: inline-block;
      height: 22px;
      color: white;
    }
    a {
      color: inherit;
      &:hover {
        span {
          text-decoration: underline;
        }
      }
      &:after {
        content: "→";
        color: inherit;
        margin-left: 5px;
        height: 100%;
        display: inline-block;
      }
    }
    &:last-of-type {
      a {
        &:after {
          content: none;
        }
      }
    }
  }
}

.section-page {
  padding-top: 40px;
  padding-bottom: 120px;
  .page-nav {
    width: 27%;
    background-color: $aqua;
    padding: 30px 50px 10px;
    padding-right: 10px;
    top:-15px;

    &:after {
      background-image: inline('../img/aqua-bottom.png');
    }


    @mixin breakpoint 800px {
      width: 40%;
    }

    @mixin breakpoint 700px {
      width: 100%;
      float: none;
      top: 0;
      max-width: 400px;
    }
  }

  .description {
    width: 65%;
    color: $blue;
    font-weight:$font-light;
    font-size:18px;
    box-sizing: border-box;
    padding-top: 40px;
    h1 {
      font-size:28px;
      font-weight:$font-light;
      margin-bottom: 15px;
    }
    @mixin breakpoint 960px {
      width: 55%;
    }
    @mixin breakpoint 800px {
      width: 50%;
    }
    @mixin breakpoint 700px {
      width: 100%;
      clear: both;
      float: none;
    }
  }
  .main-img {

    margin-left: 10px;
    margin-bottom: 10px;
    @mixin breakpoint 600px {
      width: 100%;
      margin: 20px 0;
      float: none;
    }
  }
  .img {
    width: 27%;
    position: relative;
    top: 40px;
    text-align: right;
    @mixin breakpoint 800px {
      width: 40%;
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 300px;
    }
  }
}

.persons-wrap {
  clear: both;
  margin-top: 25px;
  float: left;
  > h1 {
    font-size: 28px;
    font-weight: $font-light;
    margin-bottom: 15px;
  }
  .person {
    margin-bottom: 25px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .img {
      float: left;
      box-sizing: border-box;
      width: 25%;
      padding-right: 30px;
      top: 5px;
      img {
        width: 100%;
        height: auto;
        max-height: none;
      }
    }
    .info {
      float: right;
      width: 75%;
      color: $blue;
      p {
        margin-bottom: 10px;
      }
    }
    @mixin breakpoint 700px {
      .img {
        width: 40%;
      }
      .info {
        width: 60%;
      }
    }
    @mixin breakpoint 500px {
      margin-bottom: 35px;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding-bottom: 15px;
      &:last-of-type {
        border-bottom: none;
      }

      .img {
        width: 100%;
        margin-bottom: 15px;
      }
      .info {
        width: 100%;
      }
    }

    .img {
      &:hover {
        + .info h1 a {
          text-decoration: underline;
        }
      }
    }
    h1 {
      font-size:20px;
      font-weight:$font-light;
      a {
        color: $blue;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    h2 {
      color: $light-blue;
      font-size: 16px;
      font-style: italic;;
      margin-bottom: 10px;
      font-weight:$font-light;
    }
    .desc {
      font-size:16px;
      font-weight:$font-light;
      line-height:1.6;
      strong, b {
        font-weight:$font-regular;
      }
    }

  }
}
/*Page ends*/

/*Gallery starts*/
.albums-wrap, .photos-wrap {
  clear: both;
  margin-top: 20px;
}
.album, .photo {
  width: 30%;
  float: left;
  font-weight:$font-light;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  @mixin clf;
  margin-bottom: 20px;
  &:hover {
    opacity: .8;
  }
  &:nth-of-type(3n-1) {
    margin: 0 5% 20px;
  }
  &:nth-of-type(3n+1) {
    clear: left;
  }
  .info {
    h1 {
      font-size:23px;
      text-align: center;
      margin-bottom: 5px;
      a {
        color: black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  a {
    outline:none;
  }

  .img-wrap {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    &:hover {
      + .info h1 a {
        text-decoration: underline;;
      }
    }
  }

  @mixin breakpoint 450px {
    width: 100%;
    float: none;
    margin: 0 0 40px !important;
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}
.photo {
  .info {
    h1 {
      font-size:14px;
    }
  }
}
/*Gallery ends*/

/*Footer starts*/
.footer {
  background-color: $aqua;
  padding: 20px 0 50px;
  &:after {
    content: none;
  }

  .left {
    width: 70%;
  }

  .nav {
    width: 70%;
    @mixin breakpoint 700px {
      display: none;
    }
  }

  h2 {
    float: left;
    clear: both;
    color: white;
    font-size:14px;
    font-weight:$font-regular;
    margin-bottom: 20px;
    width: 70%;
    opacity:.7;
  }
}

.jufy {
  opacity:.3;
  transition: opacity .2s ease;
  display: block;
  background-image: inline('../img/jufy.png');
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  width: 80px;
  height: 45px;
  position: relative;
  top: 20px;
  &:hover {
    opacity:1;
  }
}
/*Footer ends*/
