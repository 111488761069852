$height-icon: 18px;
$width-line: 25px;
$height-line: 4px;

$transition-time: 0.4s;
$rotation: 45deg;
$translateY: (calc($height-icon / 2));
$translateX: 0;
.hamburger-icon {
  display: none;
  width:$width-line;
  height:$height-icon;
  position:relative;
  display: inline-block;
  margin-right: 0px;
  opacity: 1;
  padding: 0 20px;
  &:hover {
    @include opacity(.7);
  }

  .line {
    display:block;
    background:#fff;
    width:$width-line;
    height:$height-line;
    position:absolute;
    left:0;
    transition: all $transition-time;

    &.line-1 {
      top:0;
    }
    &.line-2 {
      top:50%;
    }
    &.line-3 {
      top:100%;
    }
  }
  &:hover, &:focus {
    .line-1 {
      transform: translateY(calc($height-line / 2 * -1));
    }
    .line-3 {
      transform: translateY(calc($height-line / 2));
    }
  }
  &.active {
    .line-1 {
      transform: translateY($translateY) translateX($translateX) rotate($rotation);
    }
    .line-2 {
      opacity:0;
    }
    .line-3 {
      transform: translateY(calc($translateY * -1)) translateX($translateX) rotate(calc($rotation * -1));
    }
  }
}