.section-news {
  margin: 100px;
  .news-wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .section-news .news-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}


/**
  RSS NEWS
 */
.b-news-middle__wrap {
  height: 248px;
  padding: 21px;
  position: relative;
  background-color: #f0f0f0;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.b-news-middle__wrap:hover {
  box-shadow: 0px 0px 12px 0px rgba(62, 70, 75, 0.4);
}

.b-news-middle__wrap:hover .b-news-middle__title {
  color: #d92655;
}

.b-news-middle__link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}

.b-news-middle__type {
  font-size: 0;
  padding-bottom: 12px;
  position: relative;
  z-index: 3;
}

.b-news-middle__content {
  padding-bottom: 22px;
}

.b-news-middle__description {
  color: #707477;
  line-height: 1.4;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 142px;
}

.b-news-middle__bottom {
  left: 21px;
  right: 21px;
  bottom: 21px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0 8px;
  height: 22px;
}

@media (max-width: 1024px) {
  .b-news-middle {
    padding: 12px;
  }
}

@media (max-width: 720px) {
  .b-news-middle__wrap {
    height: auto;
    padding: 21px 14px;
  }

  .b-news-middle__content {
    padding-bottom: 43px;
  }

  .b-news-middle__bottom {
    left: 14px;
    right: 14px;
    bottom: 20px;
  }
}

.b-news-middle-bg {
  padding: 13px;
}

.b-news-middle-bg__wrap {
  height: 248px;
  padding: 21px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.b-news-middle-bg__wrap:before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(58, 103, 217, .77);
  z-index: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.b-news-middle-bg__wrap:hover {
  box-shadow: 0px 0px 12px 0px rgba(62, 70, 75, 0.4);
}

.b-news-middle-bg__wrap:hover::before {
  background-color: rgba(11, 19, 92, .77);
}

.b-news-middle-bg__wrap:hover .b-news-middle-bg__title {
  color: #aeafb0;
}

.b-news-middle-bg__link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}

.b-news-middle-bg__type {
  font-size: 0;
  padding-bottom: 12px;
  z-index: 3;
  position: relative;
}

.b-news-middle-bg__content {
  padding-bottom: 22px;
  position: relative;
}

.b-news-middle-bg__title {
  font-size: 18px;
  color: #ffffff;
  padding-bottom: 12px;
  line-height: 1.2;
  font-weight: 500;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.b-news-middle-bg__bottom {
  position: absolute;
  z-index: 1;
  left: 21px;
  right: 21px;
  bottom: 21px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 22px;
}

@media (max-width: 1024px) {
  .b-news-middle-bg {
    padding: 12px;
  }
}

@media (max-width: 720px) {
  .b-news-middle-bg__wrap {
    height: 185px;
    padding: 21px 14px;
  }

  .b-news-middle-bg__bottom {
    left: 14px;
    right: 14px;
    bottom: 20px;
  }
}

.b-news-small {
  padding: 13px;
}

.b-news-small__wrap {
  /*height: 111px;*/
  padding: 21px;
  position: relative;
  background-color: #f0f0f0;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.b-news-small__wrap:hover {
  box-shadow: 0px 0px 12px 0px rgba(62, 70, 75, 0.4);
}

.b-news-small__wrap:hover .b-news-small__title {
  color: #d92655;
}

.b-news-small__link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}

.b-news-small__type {
  font-size: 0;
  padding-bottom: 12px;
  position: relative;
  z-index: 3;
}

.b-news-small__content {
  padding-bottom: 22px;
}

.b-news-small__title {
  font-size: 16px;
  color: #555961;
  padding-bottom: 12px;
  line-height: 1.2;
  font-weight: bold;
  /*width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;*/
  -webkit-transition: all .2s;
  transition: all .2s;
}

.b-news-small__description {
  color: #707477;
  line-height: 1.7;
}

.b-news-small__bottom {
  position: absolute;
  left: 21px;
  right: 21px;
  bottom: 21px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 22px;
}

@media (max-width: 1024px) {
  .b-news-small {
    padding: 12px;
  }
}

@media (max-width: 720px) {
  .b-news-small__wrap {
    /*height: 104px;*/
    padding: 20px 14px;
  }

  .b-news-small__bottom {
    left: 14px;
    right: 14px;
    bottom: 12px;
  }
}

.b-news-big__content {
  height: 225px;
  overflow: hidden;
}

.b-news-middle__title{
  color: #555961;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: -42px;
  height: 58px;
  overflow: hidden;
  padding-bottom:0;
  -webkit-transition: all .2s;
  transition: all .2s;
  margin-bottom: 10px;

}

.b-news-big__title:before {
  background-image: linear-gradient(to bottom, rgba(215, 215, 215, 0), #f0f0f0 75%);
  content: "";
  display: block;
  height: 30px;
  position: relative;
  top: 100px;
}


.b-news-middle__content::before {
  background-image: linear-gradient(to bottom, rgba(215, 215, 215, 0), #f0f0f0 75%);
  content: "";
  display: block;
  height: 30px;
  position: relative;
  top: 191px;
}

/*
.b-news-middle__title::before {
  background-image: linear-gradient(to bottom, rgba(215, 215, 215, 0), #f0f0f0 75%);
  content: "";
  display: block;
  height: 30px;
  position: relative;
  top: 67px;
}
*/


.b-date__icon {
  display: inline-block;
  vertical-align: middle;
  font-size: initial;
  fill: #909090;
  opacity: .5;
}

.b-date__date {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  margin-left: 4px;
  color: #707477;
}

.b-date--white .b-date__date {
  color: #ffffff;
}

.b-date--white .b-date__icon {
  fill: #ffffff;
  opacity: .5;
}
.b__date {
  font-size: 12px;
}
.b__icon, .b__date {
  color: #909090;
}
.b-news-middle__wrap:hover {
  box-shadow: 0px 0px 12px 0px rgb(62 70 75 / 40%);
}
.animate-3d.showed .animate-3d__wrap {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 1;
}
.animate-3d__wrap {
  -webkit-transform: rotateX(
                  -90deg);
  transform: rotateX(
                  -90deg);
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
}
